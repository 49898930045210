const Hours = () => {
  return (
    <section className="container">
      <article className="Hours">
        <div className="article-header">
          <h3>Öppettider</h3>
        </div>
        <div className="days-row days">
          <div className="day">
            <h4>Måndag</h4>
            <p className="Time">Stängt</p>
          </div>
          <div className="day">
            <h4>Tisdag</h4>
            <p className="Time">Stängt</p>
          </div>
          <div className="day">
            <h4>Onsdag</h4>
            <p className="Time">Stängt</p>
          </div>
          <div className="day">
            <h4>Torsdag</h4>
            <p className="Time">Stängt</p>
          </div>
          <div className="day">
            <h4>Fredag</h4>
            <p className="Time">Stängt</p>
          </div>
          <div className="day">
            <h4>Lördag</h4>
            <p className="Time">Stängt</p>
          </div>
          <div className="day">
            <h4>Söndag</h4>
            <p className="Time">Stängt</p>
          </div>
        </div>
      </article>
      <article>
        <div className="article-header">
          <h3>Speciella tillfällen</h3>
        </div>
        <div className="days-row special-events">
          <div className="day">
            <p className="Time">
              <span className="Date">—</span>
            </p>
            <p className="Description">
              Följ oss på{' '}
              <a target="_blank" rel="noreferrer" href="https://instagram.com/studserodgard">
                Instagram
              </a>{' '}
              för de senaste nyheterna.
            </p>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Hours;
