import React, { useEffect, useState } from 'react';
import styles from './Instagram-feed.css';

const InstagramFeed = () => {
  const [instagramData, setInstagramData] = useState(null);

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  const accesToken =
    'IGQWRNSHhJSk9Ham1oSkhSNDdzajNJbmpoUjdPUmpIYU9iQWE5N3lTRlF6Y1ptMU1LV0xVNFM1T3hhNnpuLVNXbnJWNjI4RzJReGdpSUVNckdmLXRocnJwemZAteTRBZAFA1cEtrMzRBcUV6Yl8wZAFl4NWFxOVhYTFEZD';
  const apiUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_url&access_token=${accesToken}`;

  const fetchData = async () => {
    const response = await fetch(apiUrl);
    const result = await response.json();
    setInstagramData(result.data);
    console.log(instagramData);
  };

  const limit = 8;

  return (
    <>
      {/* {instagramData?.length > 0 && (
        <article className="container">
          <div className="article-header">
            <h3>
              Följ oss på Instagram: {''}
              <a target="_blank" href="https://instagram.com/studserodgard">
                @studserodgard
              </a>
            </h3>
          </div>
          <div className="instagram-feed">
            {instagramData?.slice(0, limit).map((item) => (
              <div key={item.id} className="instagram-post">
                <a target="_blank" href="https://instagram.com/studserodgard">
                  <img src={item.media_url} alt="Instagram Post" />
                </a>
                {item.caption && <p>{item.caption}</p>}
              </div>
            ))}
          </div>
        </article>
      )} */}

      {instagramData?.length > 0 && (
        <article className="container">
          <div className="article-header">
            <h3>
              Följ oss på Instagram: {''}
              <a target="_blank" href="https://instagram.com/studserodgard">
                @studserodgard
              </a>
            </h3>
          </div>
          <div className="instagram-feed">
            {instagramData?.slice(0, limit).map((item) => (
              <div key={item.id} className="instagram-post">
                {item.media_url.includes('.jpg') ||
                item.media_url.includes('.png') ? (
                  <a target="_blank" href="https://instagram.com/studserodgard">
                    <img src={item.media_url} alt="Instagram Post" />
                  </a>
                ) : item.media_url.includes('.mp4') ||
                  item.media_url.includes('.mov') ? (
                  <a target="_blank" href="https://instagram.com/studserodgard">
                    <video controls>
                      <source src={item.media_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </a>
                ) : null}
                {/* {item.caption && <p>{item.caption}</p>} */}
              </div>
            ))}
          </div>
        </article>
      )}
    </>
  );
};

export default InstagramFeed;
