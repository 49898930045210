import { Link } from 'react-router-dom';

const Intro = () => {
  return (
    <article className="intro text-and-image min-view-height container-full-width">
      <div className="text flex">
        <h2>
          Vi driver en familjegård med lite försäljning sedan några år tillbaka.
        </h2>
        <p>
          Läs mer <Link to="/about">om oss</Link>...
        </p>
      </div>
      <div className="image"></div>
    </article>
  );
};

export default Intro;
