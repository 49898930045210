import { Link } from 'react-router-dom';

const About = () => {
  return (
    <section className="container-full-width">
      <article className="about text-and-image min-view-height">
        <div className="text flex">
          {/* <h1>Om oss</h1> */}
          <p>
            Vi driver en familjegård med lite försäljning sedan några år
            tillbaka. Det är jag Maria och min mamma Marita som har mest med
            djuren att göra. Vårt intresse för djur växer för varje dag som går,
            vi vill så mycket och planerna är många. Marita har jobbat med djur
            under manga år och alltid haft djur runt sig, vilket då "smittat" av
            sig på mig Maria under min uppvaxt på gården.
          </p>
          <p>
            Ha en trevlig vistelse på vår sida och tveka inte på att kontakta
            oss vid fragor. Med vänliga hälsningar,
          </p>
          <p>Maria & Marita</p>
          <p>
            Se våran <Link to="/hours">öppettider</Link>...
          </p>
        </div>
        <div className="image"></div>
      </article>
    </section>
  );
};

export default About;
