const Footer = () => {
  return (
    <div className="container">
      <footer>
        <p>Studseröd Gård © 2024</p>
      </footer>
    </div>
  );
};

export default Footer;
