import Intro from './Home/Intro';
import InstagramFeed from './Home/Instagram-feed';

const Home = () => {
  return (
    <section>
      <Intro></Intro>
      <InstagramFeed></InstagramFeed>
    </section>
  );
};

export default Home;
